import { SessionService } from '../services/session.service';
import { Router, ActivatedRouteSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class QuestionnaireGuard  {
    questionnaireUrl: string = environment.questionnaireUrl;

    constructor(private router: Router, private sessionService: SessionService) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const redirectUrl = route.pathFromRoot
            .map((v) => v.url.map((segment) => segment.toString()).join('/'))
            .join('/');

        const url = this.sessionService.getToken() ? this.questionnaireUrl : '/login';

        // if (!this.sessionService.isQuestionnairePageActive()) {
        //     return true;
        // }

        const user = this.sessionService.getCurrentUser();
        if (user.state === 'platform' || user.is_admin) {
            return true;
        }

        // this.router.navigateByUrl(this.router.createUrlTree([url], { queryParams: { redirectUrl } }), {
        //     skipLocationChange: false,
        // });

        this.router.navigate([url]);

        return false;
    }
}
