import { LoginService } from '../../services/login.service';
import { Component } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { SessionService } from 'src/app/services/session.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
    constructor(
        public loginService: LoginService,
        public sessionService: SessionService,
        private appService: AppService,
        private router: Router
    ) {}

    routesWithoutSidebar: string[] = ['questionnaire'];

    getClasses() {
        const classes = {
            wrapper: this.loginService.isLoggedIn(),
            'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
            'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled,
        };
        return classes;
    }

    toggleSidebar() {
        this.appService.toggleSidebar();
    }

    get simplified(): boolean {
        const urlTree = this.router.parseUrl(this.router.url);
        const urlWithoutParams = urlTree.root.children['primary'].segments.map((it) => it.path).join('/');
        return !this.routesWithoutSidebar.includes(urlWithoutParams);
    }
}
