import { Table } from './Table';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef, NgZone } from '@angular/core';
import { FcbFormElement } from '../ui/table/table-form/table-form.component';
import { forEach } from '@angular-devkit/schematics';

export class SurveyQuestionsTable extends Table {
    mode: string = 'options';
    constructor() {
        super();
        this.buildColumns();
    }

    buildColumns() {
        this.columns = [
            {
                name: 'ID',
                selector: 'id',
            },
            {
                name: 'Category',
                selector: 'category',
                headerClass: 'text-left',
                class: () => 'text-left',
            },
            {
                name: 'Question',
                selector: 'text',
                headerClass: 'text-left',
                class: () => 'text-left',
            },
            {
                name: 'Max Score',
                selector: 'max_score',
                headerClass: 'text-center',
                class: () => 'text-center',
            },
            {
                name: 'Options',
                selector: 'options',
                headerClass: 'text-left',
                class: () => 'text-left',
                transform: (options) => options.join(', '),
            },
        ];
    }
    buildForm(): void {
        this.form = {
            title: () => 'Manage Survey Questions',
            elements: [
                {
                    name: 'ID',
                    selector: 'id',
                    type: 'text',
                    visible: false,
                },
                {
                    name: 'Category',
                    selector: 'category',
                    type: 'text',
                    class: 'col-span-12',
                },
                {
                    name: 'Question',
                    selector: 'text',
                    type: 'text',
                    class: 'col-span-12',
                },
                {
                    name: 'Mode',
                    selector: 'mode',
                    type: 'select',
                    class: 'col-span-12',
                    options: [
                        { label: 'Score', value: 'score' },
                        { label: 'Options', value: 'options' },
                    ],
                    optionValueSelector: 'value',
                    optionTextSelector: 'label',
                    //value: 'score',
                    validators: [Validators.required],

                    onChange: (value: string, form) => {
                        form.formElements.forEach((formElement) => {
                            if (formElement.selector === 'max_score') {
                                formElement.class = 'col-span-12' + (value !== 'score' ? ' hidden' : '');
                            }
                            if (formElement.selector === 'options') {
                                formElement.class = 'col-span-12' + (value !== 'options' ? ' hidden' : '');
                            }
                            this.mode = value;
                            console.log(formElement);
                        });
                        // console.log(form.formGroup.value);
                    },
                },
                {
                    name: 'Max Score',
                    selector: 'max_score',
                    type: 'number',
                    //class: 'col-span-12',
                    class: `col-span-12 ${this.mode === 'score' ? '' : 'hidden'}`,

                    isPresent(formGroup?: UntypedFormGroup, formElementsOrig?: FcbFormElement[]): boolean {
                        console.log('max score present', formGroup.value['mode'] === 'score');
                        return formGroup.value['mode'] === 'score';
                    },
                },
                {
                    name: 'Options',
                    selector: 'options',
                    type: 'stringlist',
                    //class: 'col-span-12',
                    //class: `col-span-12 ${this.form.elements.find((el) => el.selector === 'mode')?.formControl?.value === 'options' ? '' : 'hidden'}`,
                    class: `col-span-12 ${this.mode === 'options' ? '' : 'hidden'}`,

                    onChange: (value: string, form) => {
                        console.log(value);
                    },
                    isPresent(formGroup?: UntypedFormGroup, formElementsOrig?: FcbFormElement[]): boolean {
                        console.log('options present', formGroup.value['mode'] === 'options');
                        return formGroup.value['mode'] === 'options';
                    },
                },
            ],
        };
        console.log(this.form);
    }
}
