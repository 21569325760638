import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class SurveysService {
    public API: string = '/school-surveys';

    constructor(private http: HttpClient) {}

    // Get all School Surveys
    getAllSchoolSurveys() {
        return this.http.get(new Url(this.API).path);
    }

    // Get all eligible school surveys for the current user
    getEligibleSchoolSurveys() {
        return this.http.get(new Url(this.API + '/eligible').path);
    }

    // Get School Survey by ID
    getSchoolSurveyById(surveyId: number) {
        return this.http.get(new Url(this.API + '/' + surveyId).path);
    }

    // Get Survey Questions by Survey ID
    getSurveyQuestions(surveyId: number) {
        return this.http.get(new Url(this.API + '/' + surveyId + '/questions').path);
    }

    // Update School Survey by ID
    updateSchoolSurvey(surveyId: number, data: any) {
        return this.http.put(new Url(this.API + '/' + surveyId).path, data);
    }

    // Add new School Survey
    addSchoolSurvey(data: any) {
        return this.http.post(new Url(this.API).path, data);
    }

    // Add multiple answers to a school survey
    addSurveyAnswers(surveyId: number, answers: any) {
        return this.http.post(new Url(this.API + '/' + surveyId + '/save-answers').path, answers);
    }

    // Delete School Survey by ID
    deleteSchoolSurvey(surveyId: number) {
        return this.http.delete(new Url(this.API + '/' + surveyId).path);
    }

    // Add new Survey Question
    addSurveyQuestion(data: any) {
        return this.http.post(new Url('/school-survey-questions').path, data);
    }

    // Update Survey Question by ID
    updateSurveyQuestion(questionId: number, data: any) {
        return this.http.put(new Url('/school-survey-questions/' + questionId).path, data);
    }

    // Delete Survey Question by ID
    deleteSurveyQuestion(questionId: number) {
        return this.http.delete(new Url('/school-survey-questions/' + questionId).path);
    }

    // Add new answer to school survey
    addSurveyAnswer(data: {
        project_id: number;
        response: string;
        school_id: number;
        school_survey_question_id: number;
        target_student_id: number;
        team_id: number;
        user_id: number;
    }) {
        return this.http.post('/api/school-survey-answers', data);
    }
}
