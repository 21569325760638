import { ChangeDetectorRef, Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationService } from '../../../../services/notification.service';
import { SessionService } from '../../../../services/session.service';
import { SurveysService } from '../../../../services/surveys.service';
import { environment } from '../../../../../environments/environment';
import { finalize } from 'rxjs/operators';
import { ManageSurveysTable } from '../../../../tables/ManageSurveysTable';
import { SurveyQuestionsTable } from '../../../../tables/SurveyQuestionsTable';

interface Survey {
    id?: number;
    is_mentor: boolean;
    name_en: string;
    name_hb?: string;
    project_id?: number;
    roles: number[];
    roles_objects?: RolesObject[];
    school_name?: string;
    school_id?: number;
    status?: number;
}

interface RolesObject {
    id: number;
    label: string;
    name: string;
}

@Component({
    selector: 'app-manage-surveys',
    templateUrl: './manage-surveys.component.html',
    styleUrls: ['./manage-surveys.component.scss'],
})
export class ManageSurveysComponent implements OnInit {
    session: any;
    surveys: any[] = [];
    ENTRY_NAME: string = 'Surveys';
    table = new ManageSurveysTable();
    search: string = '';
    storageUrl: string = environment.storageUrl;
    isLoading: boolean = true;

    selectedSurvey: Survey | null = null;
    surveyQuestions: any[] = [];
    questionsTable = new SurveyQuestionsTable();
    isLoadingQuestions: boolean = false;

    constructor(
        private notificationService: NotificationService,
        private sessionService: SessionService,
        private surveyService: SurveysService,
    ) {}

    ngOnInit(): void {
        this.fetch();
    }

    @ViewChild('selectButtonTemplate', { static: true }) selectButtonTemplate: TemplateRef<any>;

    fetch() {
        this.isLoading = true;

        this.surveyService
            .getAllSchoolSurveys()
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(({ data }: any) => {
                this.surveys = data.school_surveys || [];
                this.isLoading = false;
                //console.log(this.surveys);
                this.table.selectButtonTemplate = this.selectButtonTemplate;
                this.table.buildColumns();
                this.table.buildForm(data.roles, data.projects);
            });
    }

    create(data: Survey) {
        this.isLoading = true;

        // Map roles_objects to roles, handling both string and object cases
        const roles =
            data.roles_objects
                ?.map((role: any) => {
                    if (typeof role === 'string') {
                        return parseInt(role);
                    } else if (typeof role === 'object' && role.id) {
                        return role.id;
                    }
                    return null;
                })
                .filter((role) => role !== null) || [];

        // Transform is_mentor to 1 or 0
        const is_mentor = data.is_mentor ? 1 : 0;

        const { roles_objects, ...payload } = { ...data, roles, is_mentor };

        //console.log('roles:', roles);
        this.surveyService
            .addSchoolSurvey(payload)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success(this.ENTRY_NAME + ' created successfully');
                    this.fetch();
                },
                error: (error) => {
                    this.notificationService.error(error.error.message, 'Error');
                },
            });
    }

    update(data: Survey) {
        this.isLoading = true;

        // Map roles_objects to roles, handling both string and object cases
        const roles =
            data.roles_objects
                ?.map((role: any) => {
                    if (typeof role === 'string') {
                        return parseInt(role);
                    } else if (typeof role === 'object' && role.id) {
                        return role.id;
                    }
                    return null;
                })
                .filter((role) => role !== null) || [];

        // Transform is_mentor to 1 or 0
        const is_mentor = data.is_mentor ? 1 : 0;

        const { id, roles_objects, ...payload } = { ...data, roles, is_mentor };

        this.surveyService
            .updateSchoolSurvey(id, payload)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success(this.ENTRY_NAME + ' updated successfully');
                    this.fetch();
                },
                error: (error) => {
                    this.notificationService.error(error.error.message, 'Error');
                },
            });
    }

    delete(data: any) {
        this.isLoading = true;
        this.surveyService
            .deleteSchoolSurvey(data.id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success(this.ENTRY_NAME + ' deleted successfully');
                    this.fetch();
                },
                error: (error) => {
                    this.notificationService.error(error.error.message, 'Error');
                },
            });
    }

    onSurveySelect(survey: Survey) {
        this.selectedSurvey = survey;
        this.fetchSurveyQuestions(survey.id);
        //console.log('button clicked');
    }

    fetchSurveyQuestions(surveyId: number) {
        this.isLoadingQuestions = true;
        this.surveyQuestions = []; // Reset to empty array before fetching
        this.surveyService
            .getSurveyQuestions(surveyId)
            .pipe(finalize(() => (this.isLoadingQuestions = false)))
            .subscribe({
                next: (response: any) => {
                    if (response && response.data && Array.isArray(response.data.questions)) {
                        this.surveyQuestions = response.data.questions.map((question) => {
                            let mode: string = 'score';

                            const hasMaxScore = !!question.max_score;
                            const hasOptions = Array.isArray(question.options) && question.options.length > 0;

                            // Determine the mode based on the provided conditions
                            if (hasOptions || (hasMaxScore && hasOptions)) {
                                mode = 'options';
                            } else if (hasMaxScore && !hasOptions) {
                                mode = 'score';
                            }

                            return {
                                ...question,
                                options:
                                    typeof question.options === 'string'
                                        ? JSON.parse(question.options)
                                        : question.options,
                                mode: mode, // Set mode based on conditions
                            };
                        });
                        this.questionsTable.buildForm();
                    } else {
                        console.error('Unexpected response format:', response);
                        this.surveyQuestions = [];
                    }
                },
                error: (error) => {
                    console.error('Error fetching survey questions:', error);
                    this.notificationService.error('Failed to fetch survey questions', 'Error');
                    this.surveyQuestions = []; // Reset to empty array on error
                },
            });
    }

    createQuestion(data: any) {
        this.isLoadingQuestions = true;
        data.school_survey_id = this.selectedSurvey?.id; // Add survey ID to the data

        //exclude mode from payload
        const { mode, ...payload } = data;

        this.surveyService
            .addSurveyQuestion(payload)
            .pipe(finalize(() => (this.isLoadingQuestions = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success('Question created successfully');
                    this.fetchSurveyQuestions(this.selectedSurvey?.id);
                },
                error: (error) => {
                    this.notificationService.error(error.error.message, 'Error');
                },
            });
    }

    // ManageSurveysComponent.ts

    updateQuestion(data: any) {
        this.isLoadingQuestions = true;
        const { id, mode, ...payload } = data; // Exclude id from payload
        //also exclude mode from payload

        this.surveyService
            .updateSurveyQuestion(id, payload) // Pass id separately
            .pipe(finalize(() => (this.isLoadingQuestions = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success('Question updated successfully');
                    this.fetchSurveyQuestions(this.selectedSurvey?.id);
                },
                error: (error) => {
                    this.notificationService.error(error.error.message, 'Error');
                },
            });
    }

    deleteQuestion(data: any) {
        this.isLoadingQuestions = true;

        this.surveyService
            .deleteSurveyQuestion(data.id)
            .pipe(finalize(() => (this.isLoadingQuestions = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success('Question deleted successfully');
                    this.fetchSurveyQuestions(this.selectedSurvey?.id);
                },
                error: (error) => {
                    this.notificationService.error(error.error.message, 'Error');
                },
            });
    }
}
