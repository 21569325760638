import { SessionService } from './services/session.service';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { trigger, transition, style, query, animateChild, group, animate } from '@angular/animations';
import * as moment from 'moment';
import { TextDirectionController } from './classes/TextDirectionController';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

export const MAT_DATE_FORMATS_CUSTOM = {
    parse: {
        dateInput: 'DD.MM.YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

const slideInAnimation = trigger('routeAnimations', [
    transition('page <=> page', [
        style({ position: 'relative' }),
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                }),
            ],
            { optional: true }
        ),
        query(':enter', [style({ left: '-40px' })], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(':leave', [animate('300ms ease-out', style({ left: '40px' }))]),
            query(':enter', [animate('300ms ease-out', style({ left: '0' }))]),
        ]),
        query(':enter', animateChild(), { optional: true }),
    ]),
    transition('* <=> FilterPage', [
        style({ position: 'relative' }),
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '40px',
                }),
            ],
            { optional: true }
        ),
        query(':enter', [style({ left: '-40px' })], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(':leave', [animate('200ms ease-out', style({ left: '40px' }))], { optional: true }),
            query(':enter', [animate('300ms ease-out', style({ left: '0px' }))], { optional: true }),
        ]),
        query(':enter', animateChild(), { optional: true }),
    ]),
]);

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [slideInAnimation],
    providers: [SessionService],
})
export class AppComponent implements OnInit {
    title: string = 'Unispher';
    isLoading: boolean = true;

    public directionController = new TextDirectionController();

    subscriptions: Subscription[] = [];

    customIcons = ['communication', 'game-controller', 'graduation-cap', 'home', 'palette', 'sidebar'];

    constructor(
        public translate: TranslateService,
        private sessionService: SessionService,
        private renderer: Renderer2,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        translate.addLangs(['en', 'heb']);
        translate.setDefaultLang(environment.defaultLanguage);

        this.subscriptions.push(
            this.sessionService.changeLang$.subscribe(() => {
                this.directionController.checkDirection();
                this.setDirection();
            })
        );

        this.setDirection();

        // this.customIcons.forEach(customIcon => {
        //   this.matIconRegistry.addSvgIcon(
        //     `un-${customIcon}`,
        //     this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/un-icons/${customIcon}.svg`)
        //   );
        // });
    }

    ngOnInit() {
        setTimeout(() => {
            this.isLoading = false;
        }, 600);

        moment.locale('en');
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }

    setDirection() {
        this.renderer.setAttribute(document.documentElement, 'dir', this.directionController.textDirection);

        const lang = localStorage.getItem('language') || environment.defaultLanguage;
        this.translate.use(lang);
    }
}
