import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { LoginService } from 'src/app/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/internal/Subscription';

interface MenuItem {
    name: string;
    icon: string;
    link: string;
    active: boolean;
    visible: boolean;
}

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
    items: MenuItem[] = [
        {
            name: 'Home',
            icon: 'ph-house',
            link: '/home',
            active: true,
            visible: true,
        },
        {
            name: 'Communications',
            icon: 'ph-users-four',
            link: '/communications',
            active: false,
            visible: true,
        },
        {
            name: 'Studio',
            icon: 'ph-palette',
            link: '/studio',
            active: false,
            visible: true,
        },
        {
            name: 'Games',
            icon: 'ph-game-controller',
            link: '/games',
            active: false,
            visible: true,
        },
    ];

    adminItems: MenuItem[] = [
        {
            name: 'Schools',
            icon: 'ph-graduation-cap',
            link: '/manage-school',
            active: false,
            visible: this.session.getCurrentUser().is_admin,
        },
        // {
        //     name: 'Organizations',
        //     icon: 'ph-identification-badge',
        //     link: '/manage-organizations',
        //     active: false,
        //     visible: this.session.getCurrentUser().is_admin,
        // },
        // {
        //     name: 'Games Dashboard',
        //     icon: 'ph-game-controller',
        //     link: '/manage-games',
        //     active: false,
        //     visible: this.session.getCurrentUser().is_admin,
        // },
        {
            name: 'Users',
            icon: 'ph-users',
            link: '/manage-users',
            active: false,
            visible: this.session.getCurrentUser().is_admin,
        },
    ];

    public href: string = '';
    subscriptions: Subscription[] = [];

    isMinimized: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public translate: TranslateService,
        public session: SessionService,
        public loginService: LoginService,
    ) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.setActive(val.url);
                if (this.currentScreenSize < 768) {
                    this.isMinimized = true;
                }
            }
        });
        this.subscriptions.push(this.session.changeLang$.subscribe(() => this.initTranslate()));
        this.subscriptions.push(this.session.sideBarToggled$.subscribe(() => this.toggleFullView()));
    }

    ngOnInit() {
        this.setActive(this.router.url);
        this.initTranslate();
    }

    initTranslate() {
        this.translate.get(['sidebar']).subscribe((translations) => {
            this.items[0].name = translations['sidebar'].home;
            this.items[1].name = translations['sidebar'].communications;
            this.items[2].name = translations['sidebar'].studio;
            this.items[3].name = translations['sidebar'].games;
            this.adminItems[0].name = translations['sidebar'].schools;
            // this.adminItems[1].name = translations['sidebar'].organizations;
            // this.adminItems[2].name = translations['sidebar'].games_dashboard;
            this.adminItems[1].name = translations['sidebar'].users;
        });
    }

    selectMenu(item: MenuItem) {
        this.resetActiveItem();
        item.active = true;
    }

    setActive(url: string): void {
        this.href = url === '/' ? '/home' : url;
    }

    resetActiveItem() {
        this.items.forEach((item) => (item.active = false));
    }

    toggleFullView() {
        this.isMinimized = !this.isMinimized;
    }

    get currentScreenSize(): number {
        return window.innerWidth;
    }
}
