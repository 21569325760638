import { Table } from './Table';
import * as moment from 'moment';
import { Validators } from '@angular/forms';

export class ManageSurveysTable extends Table {
    public selectButtonTemplate: any;
    constructor() {
        super();
        this.buildColumns();
    }

    buildColumns() {
        this.columns = [
            {
                name: 'ID',
                selector: 'id',
            },
            {
                name: 'Title',
                selector: 'name_en',
                headerClass: 'text-left',
                class: () => 'text-left',
            },
            /*            {
                name: 'Title (HB)',
                selector: 'name_hb',
                headerClass: 'text-left',
                class: () => 'text-left',
            },*/
            {
                name: 'Project Title',
                selector: 'project_title',

                class: () => 'text-left',

                transform: (project_title) => {
                    if (typeof project_title === 'string' && project_title.trim() !== '') {
                        return (
                            '<span class="tag bg-purple-600 mx-2 truncate max-w-[120px]">' + project_title + '</span>'
                        );
                    } else {
                        return '<span class="tag bg-yellow-700 mx-2 truncate max-w-[120px]"> No Project </span>';
                    }
                },
            },
            {
                name: 'Roles',
                selector: 'roles_objects',
                headerClass: 'text-left',
                class: () => 'text-left',
                transform: (roles) => {
                    return roles
                        .map((role) => {
                            // Assign colors based on the role name
                            let colorClass = 'bg-gray-500'; // Default color

                            if (role.name === 'teacher') {
                                colorClass = 'bg-blue-600'; // Color for Teacher
                            } else if (role.name === 'student') {
                                colorClass = 'bg-green-600'; // Color for Student
                            }

                            return `<span class="tag ${colorClass} mx-2 truncate max-w-[120px]">${role.label}</span>`;
                        })
                        .join('');
                },
            },
            /*            {
                name: 'School ID',
                selector: 'school_id',
            },*/
            {
                name: 'Status',
                selector: 'status',
                headerClass: 'text-center',
                class: () => 'text-center',
                // transform status like tag with different colors. if (status === 'active' then green, if "in progress" then yellow, if  "not started" then red)
                transform: (status) => {
                    if (status === 'completed') {
                        return '<span class="tag bg-emerald-800 mx-2 truncate max-w-[120px]">Active</span>';
                    } else if (status === 'in progress') {
                        return '<span class="tag bg-yellow-700 mx-2 truncate max-w-[120px]">In Progress</span>';
                    } else if (status === 'not started') {
                        return '<span class="tag bg-red-700 mx-2 truncate max-w-[120px]">Not Started</span>';
                    }
                },

                /*                transform: (status) =>
                    '<span class="tag bg-emerald-800 mx-2 truncate max-w-[120px]">' + status + '</span>',*/
            },
            {
                name: 'is Mentor',
                selector: 'is_mentor',
                headerClass: 'text-nowrap text-center',
                class: () => 'text-center',
                transform: (active) =>
                    active ? '<i class="ph ph-circle text-green"></i>' : '<i class="ph ph-circle text-red"></i>',
            },
            {
                name: 'Select',
                selector: 'id',
                headerClass: 'text-center',
                class: () => 'text-center interactive',
                content: {
                    template: this.selectButtonTemplate,
                    context: (row) => {
                        return {
                            survey: row,
                        };
                    },
                },
            },
        ];
    }

    buildForm(availableRoles, projects): void {
        this.form = {
            title: () => 'Manage surveys',
            elements: [
                {
                    name: 'ID',
                    selector: 'id',
                    type: 'text',
                    visible: false,
                },
                {
                    name: 'Title',
                    selector: 'name_en',
                    type: 'text',
                    class: 'col-span-12',
                    validators: [Validators.required],
                },
                /*                {
                    name: 'Title (HB)',
                    selector: 'name_hb',
                    type: 'text',
                    class: 'col-span-12',
                    validators: [Validators.required],
                },*/
                /*                {
                    name: 'Project ID',
                    selector: 'project_id',
                    type: 'number',
                    class: 'col-span-12',
                    validators: [Validators.required],
                },*/
                {
                    name: 'Select Roles',
                    selector: 'roles_objects',
                    type: 'checkboxes',
                    class: 'col-span-12',
                    options: availableRoles,
                    optionTextSelector: 'label',
                    optionValueSelector: 'id',
                    validators: [Validators.required],
                },
                /*                {
                    name: 'School ID',
                    selector: 'school_id',
                    type: 'number',
                    class: 'col-span-12',
                    validators: [Validators.required],
                },*/
                {
                    name: 'Status',
                    selector: 'status',
                    type: 'select',
                    class: 'col-span-12',
                    options: [
                        { label: 'not started', value: 'not started' },
                        { label: 'in progress', value: 'in progress' },
                        { label: 'completed', value: 'completed' },
                    ],
                    optionValueSelector: 'value',
                    optionTextSelector: 'label',
                    validators: [Validators.required],
                },
                {
                    name: 'Select project',
                    selector: 'project_id',
                    class: 'col-span-12',
                    type: 'select',
                    options: projects,
                    optionValueSelector: 'id',
                    optionTextSelector: 'title',
                    validators: [Validators.required],
                },
                {
                    name: 'is Mentor',
                    selector: 'is_mentor',
                    type: 'checkbox',
                    class: 'col-span-12',
                    validators: [Validators.required],
                },
            ],
        };
    }
}
